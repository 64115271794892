<template>
  <div class="HtmlToPdf">
    <!-- <div>
      <button
        type="button"
        @click="fetchDataUrl"
      >Cargar dataURL</button>
    </div> -->

    <!-- <iframe
      name="my-iframe"
      width="100%"
      height="600"
      :src="pdfDataUrl"
    >
    </iframe> -->

    <object
      v-if="pdfDataUrl"
      width="100%"
      height="600"
      type="application/pdf"
      :data="pdfDataUrl"
    >

      <!-- Fallback del objeto.  Mostrar el HTML -->
      <div class="pdf-container">
        <div
          class="document-html pdf-html"
          v-html="html"
        ></div>
      </div>
    </object>

    <!-- Boton "abrir" -->
    <form
      method="post"
      :action="`${generatorUrl}#zoom=${iframeOptions.zoom}&toolbar=${iframeOptions.toolbar}&scrollbar=${iframeOptions.scrollbar}&navpanes=${iframeOptions.navpanes}`"
      target="_system"
    >
      <input
        type="hidden"
        name="html"
        :value="html"
      />
      <input
        type="hidden"
        name="options"
        :value="options"
      />

      <button
        type="submit"
        class="ui-button --main"
      >Abrir</button>
      <div>
        <small>POST desde &lt;form&gt;</small>
      </div>
    </form>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';

import HtmlEditor from '@/modules/cms/components/Media/Html/HtmlEditor.vue';
import { UiInputJson } from '@/modules/ui/components';

export default {
  name: 'HtmlToPdf',
  mixins: [useApi],
  components: { UiInputJson, HtmlEditor },

  props: {
    /**
     * HTML a mostrar como PDF
     */
    html: {
      required: false,
      default: null,
    },

    /**
     * Options
     * objeto de opciones (Constructor para MPDF\MPDF)
     * https://mpdf.github.io/reference/mpdf-functions/construct.html
     * y estas: https://mpdf.github.io/reference/mpdf-variables/overview.html
     *
     * e.g. {
     *    'mode': 'utf-8',
     *    'format': [190, 236],
     *    'orientation': 'L'
     * }
     */
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    /**
     * URL del servicio generador (Phidias)
     * Debe ser un endpoint que reciba via post:
     * {
     *  html: '<h1>Hola mundo</h1>',
     *  options: { opciones de mPDF }
     * }
     */
    generatorUrl: {
      type: String,
      required: false,
      default: '/1/esign/pdf/generator',
    },
  },

  data() {
    return {
      showSource: false,
      pdfDataUrl: null,

      // En teoria deben funcionar todas estas
      // https://stackoverflow.com/a/20934796
      // pero solo esta funcionando cuando se hace POST directo hacia un iframe.
      iframeOptions: {
        zoom: 100,
        toolbar: 1,
        scrollbar: 0,
        navpanes: 0,
      },

      debounceTimeout: null,
    };
  },

  mounted() {
    if (this.html) {
      this.fetchDataUrl();
    }
  },

  watch: {
    html() {
      this.debounceReload();
    },

    options() {
      this.debounceReload();
    },
  },

  methods: {
    debounceReload() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => this.fetchDataUrl(), 600);
    },

    async fetchDataUrl() {
      this.pdfDataUrl = await this.htmlToPdfDataUrl({
        html: this.html,
        options: this.options,
      });
    },

    htmlToPdfDataUrl(body = null) {
      return this.$httpClient
        .fetch(this.generatorUrl, { method: 'POST', body })
        .then((response) => response.blob())
        .then((pdfBlob) => {
          return new Promise((resolve) => {
            let a = new FileReader();
            a.onload = (e) => resolve(e.target.result);
            a.readAsDataURL(pdfBlob);
          });
        });
    },
  },
};
</script>

<style lang="scss">
.HtmlToPdf {
  .pdf-container {
    padding: 24px 0;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .pdf-html {
    margin: auto;
    max-width: 800px;
    font-family: 'Times New Roman', Times, serif !important;
    padding: 36px 36px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px #00000042;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Times New Roman', Times, serif !important;
      font-weight: bold;
    }
  }

  .esign-generator-toolbar {
    margin: var(--ui-breathe) 0;
  }

  .external-link {
    display: inline-block;
    font-family: var(--ui-font-secondary);
    font-size: 13px;
    padding: 4px 12px;
    margin-right: 12px;
    margin-top: 12px;
  }
}
</style>